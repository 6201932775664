<template>
    <router-view></router-view>
  </template>
  
  
  <script>
  
  export default {
    name: "empresa-usuarios-general",
    
    data: () => ({}),
  };
  </script>
  
  <style>
  </style>